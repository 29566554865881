
import {Options, Vue} from 'vue-class-component';
import screenTable from './ScreenTable/index.vue';
import Service from '@/services/server/web';
import {INewsList, INews, NewsData} from '@/model';
import {LeagueSelect, ContentDetail} from '@/common/web';
@Options({
  components: {
    screenTable,
    LeagueSelect,
    ContentDetail
  }
})
export default class Login extends Vue {
  selectValue = '';

  list: Array<INews> = [];

  total = 0;

  page = 1;

  detail: INews = NewsData.getInitNewsDetail();

  showDetail = false;

  created() {
    this.getList();
  }

  async getList() {
    const res = await Service.Common.getAgentNewsList<INewsList>({
      page: this.page,
      size: 10,
      // eslint-disable-next-line @typescript-eslint/camelcase
      league_code: this.selectValue
    });
    if (!(res instanceof Error)) {
      this.list = res.data;
      this.total = res.total;
    }
  }

  pageChange(e: number) {
    this.page = e;
    this.list = [];
    this.getList();
  }

  changeSelect(e: string) {
    this.selectValue = e;
    this.initList();
  }

  initList() {
    this.list = [];
    this.total = 0;
    this.getList();
  }

  jumpDetail(e: INews) {
    this.detail = e;
    this.showDetail = true;
  }
}
