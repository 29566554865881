
import {INews} from '@/model';
import {Options, Vue} from 'vue-class-component';

@Options({
  props: {
    data: {
      type: Array
    },
    pageChange: Function,
    total: {
      type: Number
    }
  }
})
export default class Login extends Vue {
  handleClick(e: INews) {
    this.$emit('jumpDetail', e);
  }
}
